.navbar-logo{
    position: absolute;
    top: 30px;
    left: 20px;
    width: 98px;
    height: 28px;
    background: transparent url('../images/logo.png') 0% 0% no-repeat padding-box;
    opacity: 1;
}
.navbar-title{
    position: absolute;
    top: 24px;
    left: 131px;
    width: 87px;
    height: 29px;
    text-align: left;
    font: normal normal bold 18px Source Han Sans JP;
    letter-spacing: 0px;
    color: #3B4043;
    opacity: 1;
}
.navbar-description{
    position: absolute;
    top: 53px;
    left: 131px;
    width: 156px;
    height: 17px;
    text-align: left;
    font: normal normal normal 12px/20px Source Han Sans JP;
    letter-spacing: 0px;
    color: #707F89;
    opacity: 1;
}
/*
*/