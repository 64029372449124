html, body {
  overflow: scroll;
}

.container-fluid {
  padding-top: 80px; 
  overflow: scroll;
}

.font-size-24 {
  font-size: 24px;
}